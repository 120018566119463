@import 'react-phone-number-input/style.css';

.PhoneInput {
  .PhoneInputCountrySelect {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
    z-index: 1 !important;
    border: 0 !important;
    opacity: 0 !important;
    cursor: pointer !important;
  }
}

.react-autosuggest {
  &__container {
    position: relative;
  }

  &__input {
    width: 150px;
    height: 30px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;

    &--focused {
      outline: none;
    }

    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__suggestions-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: 50px;
      width: 100%;
      border: 1px solid #aaa;
      background-color: #fff;
      font-weight: 300;
      font-size: 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 3;
    }
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__suggestion {
    cursor: pointer;
    padding: 10px 20px;

    &--highlighted {
      background-color: #ddd;
    }
  }
}

.l-app-header {
  &__upload-action {
    display: none;
  }
  &__log-in {
    display: inline-flex;
  }

  &--authorized {
    .l-app-header__upload-action {
      display: inline-flex;
    }
    .l-app-header__log-in {
      display: none;
    }
  }
}

.ui-theme {
  &--authorization-page {
    .l-app-header {
      &__upload-action {
        display: inline-flex;
      }
      &__log-in {
        display: none;
      }
    }
  }
  &--checkout-page {
    .l-app-header {
      &__log-in {
        display: none;
      }
    }
  }
}

.verified-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 4px;

  svg {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
}

.document-comment {
  background-color: white;
  padding: 37px 25px;

  &__meta {
    display: flex;
    align-items: center;
    margin-bottom: 19px;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    flex-shrink: 0;
  }

  &__info {
    display: flex;
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__author {
    color: #051e56;
    font-size: 14px;
    margin-right: 3px;
  }

  &__time {
    font-size: 14px;
    color: #b3bccf;
    @media (max-width: 480px) {
      font-size: 12px;
      margin-top: 2px;
    }
  }

  &__content {
    color: #526286;
    font-size: 1rem;
    line-height: 1.5;
  }
}

.c-aside-sticky-navigation__item {
  &.is-active {
    pointer-events: auto;
  }
}
